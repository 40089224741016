import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"list-students-page__header\">\n  <h1 class=\"page__title page-title\">{{t \"pages.students-sco.title\"}}</h1>\n  {{#if this.currentUser.isAdminInOrganization}}\n    <div class=\"list-students-page__import-students-button hide-on-mobile\">\n      <PixButtonUpload @id=\"students-file-upload\" @onChange={{@onImportStudents}} accept={{this.acceptedFileType}}>\n        {{this.importButtonLabel}}\n      </PixButtonUpload>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"list-students-page__header\">\n  <h1 class=\"page__title page-title\">{{t \"pages.students-sco.title\"}}</h1>\n  {{#if this.currentUser.isAdminInOrganization}}\n    <div class=\"list-students-page__import-students-button hide-on-mobile\">\n      <PixButtonUpload @id=\"students-file-upload\" @onChange={{@onImportStudents}} accept={{this.acceptedFileType}}>\n        {{this.importButtonLabel}}\n      </PixButtonUpload>\n    </div>\n  {{/if}}\n</div>","moduleName":"pix-orga/components/student/sco/header-actions.hbs","parseOptions":{"srcName":"pix-orga/components/student/sco/header-actions.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ENV from 'pix-orga/config/environment';

export default class ScoHeaderActions extends Component {
  @service currentUser;
  @service session;
  @service intl;

  get acceptedFileType() {
    if (this.currentUser.isAgriculture) {
      return ['.csv'];
    }
    return ['.xml', '.zip'];
  }

  get importButtonLabel() {
    const types = this.acceptedFileType.join(this.intl.t('pages.students-sco.actions.import-file.file-type-separator'));
    return this.intl.t('pages.students-sco.actions.import-file.label', { types });
  }

  get urlToDownloadCsvTemplate() {
    return `${ENV.APP.API_HOST}/api/organizations/${this.currentUser.organization.id}/schooling-registrations/csv-template?accessToken=${this.session.data.authenticated.access_token}&lang=${this.currentUser.prescriber.lang}`;
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixMultiSelect\n  @id=\"group\"\n  @title={{t \"pages.campaign-results.filters.type.groups.title\"}}\n  @placeholder={{t \"pages.campaign-results.filters.type.groups.title\"}}\n  @loadingMessage={{t \"common.filters.loading\"}}\n  @isSearchable={{true}}\n  @onSelect={{@onSelect}}\n  @selected={{@selectedGroups}}\n  @emptyMessage={{t \"pages.campaign-results.filters.type.groups.empty\"}}\n  @onLoadOptions={{this.onLoadGroups}}\n  @showOptionsOnInput={{true}}\n  ...attributes\n  as |option|\n>\n  {{option.label}}\n</PixMultiSelect>", {"contents":"<PixMultiSelect\n  @id=\"group\"\n  @title={{t \"pages.campaign-results.filters.type.groups.title\"}}\n  @placeholder={{t \"pages.campaign-results.filters.type.groups.title\"}}\n  @loadingMessage={{t \"common.filters.loading\"}}\n  @isSearchable={{true}}\n  @onSelect={{@onSelect}}\n  @selected={{@selectedGroups}}\n  @emptyMessage={{t \"pages.campaign-results.filters.type.groups.empty\"}}\n  @onLoadOptions={{this.onLoadGroups}}\n  @showOptionsOnInput={{true}}\n  ...attributes\n  as |option|\n>\n  {{option.label}}\n</PixMultiSelect>","moduleName":"pix-orga/components/ui/groups-filter.hbs","parseOptions":{"srcName":"pix-orga/components/ui/groups-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class GroupsFilter extends Component {
  @action
  async onLoadGroups() {
    const groups = await this.args.campaign.groups;
    return groups?.map(({ name }) => ({ value: name, label: name }));
  }
}

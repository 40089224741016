import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PixMultiSelect\n  @id=\"division\"\n  @title={{t \"common.filters.divisions.title\"}}\n  @emptyMessage={{t \"common.filters.divisions.empty\"}}\n  @loadingMessage={{t \"common.filters.loading\"}}\n  @placeholder={{@placeholder}}\n  @selected={{@selected}}\n  @onSelect={{@onSelect}}\n  @onLoadOptions={{this.onLoadDivisions}}\n  @isSearchable={{true}}\n  @showOptionsOnInput={{true}}\n  ...attributes\n  as |option|\n>\n  {{option.label}}\n</PixMultiSelect>", {"contents":"<PixMultiSelect\n  @id=\"division\"\n  @title={{t \"common.filters.divisions.title\"}}\n  @emptyMessage={{t \"common.filters.divisions.empty\"}}\n  @loadingMessage={{t \"common.filters.loading\"}}\n  @placeholder={{@placeholder}}\n  @selected={{@selected}}\n  @onSelect={{@onSelect}}\n  @onLoadOptions={{this.onLoadDivisions}}\n  @isSearchable={{true}}\n  @showOptionsOnInput={{true}}\n  ...attributes\n  as |option|\n>\n  {{option.label}}\n</PixMultiSelect>","moduleName":"pix-orga/components/ui/divisions-filter.hbs","parseOptions":{"srcName":"pix-orga/components/ui/divisions-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class DivisionsFilter extends Component {
  @action
  async onLoadDivisions() {
    const divisions = await this.args.model.divisions;
    return divisions?.map(({ name }) => ({ value: name, label: name }));
  }
}

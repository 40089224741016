import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::SearchInput\n  @inputName={{@field}}\n  @value={{@value}}\n  @placeholder={{@placeholder}}\n  @ariaLabel={{@ariaLabel}}\n  @onSearch={{this.onSearch}}\n/>", {"contents":"<Ui::SearchInput\n  @inputName={{@field}}\n  @value={{@value}}\n  @placeholder={{@placeholder}}\n  @ariaLabel={{@ariaLabel}}\n  @onSearch={{this.onSearch}}\n/>","moduleName":"pix-orga/components/campaign/filter/search-input-filter.hbs","parseOptions":{"srcName":"pix-orga/components/campaign/filter/search-input-filter.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'pix-orga/config/environment';
import debounce from 'lodash/debounce';

export default class SearchInputFilter extends Component {
  constructor() {
    super(...arguments);
    this.debouncedTriggerFiltering = debounce(this.args.triggerFiltering, ENV.pagination.debounce);
  }

  @action
  onSearch(event) {
    this.debouncedTriggerFiltering(this.args.field, event.target.value);
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Header ...attributes>\n  <PixMultiSelect\n    @id=\"select-{{@field}}\"\n    class=\"table__multi-select\"\n    @title={{@title}}\n    @placeholder={{@placeholder}}\n    @loadingMessage={{t \"common.filters.loading\"}}\n    @emptyMessage={{@emptyMessage}}\n    aria-label={{@ariaLabel}}\n    @showOptionsOnInput={{true}}\n    @isSearchable={{true}}\n    @onSelect={{this.onSelect}}\n    @selected={{@selectedOption}}\n    @options={{@options}}\n    @onLoadOptions={{@onLoadOptions}}\n    as |option|\n  >\n    {{option.label}}\n  </PixMultiSelect>\n</Table::Header>", {"contents":"<Table::Header ...attributes>\n  <PixMultiSelect\n    @id=\"select-{{@field}}\"\n    class=\"table__multi-select\"\n    @title={{@title}}\n    @placeholder={{@placeholder}}\n    @loadingMessage={{t \"common.filters.loading\"}}\n    @emptyMessage={{@emptyMessage}}\n    aria-label={{@ariaLabel}}\n    @showOptionsOnInput={{true}}\n    @isSearchable={{true}}\n    @onSelect={{this.onSelect}}\n    @selected={{@selectedOption}}\n    @options={{@options}}\n    @onLoadOptions={{@onLoadOptions}}\n    as |option|\n  >\n    {{option.label}}\n  </PixMultiSelect>\n</Table::Header>","moduleName":"pix-orga/components/table/header-filter-multi-select.hbs","parseOptions":{"srcName":"pix-orga/components/table/header-filter-multi-select.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class HeaderFilterMultiSelect extends Component {
  @action
  onSelect(value) {
    const { onSelect, field } = this.args;
    onSelect(field, value);
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Header @size={{@size}} @align={{@align}} aria-sort={{this.order}} ...attributes>\n  <span class=\"table__column--sort\">\n    {{yield}}\n    {{#unless @isDisabled}}\n      <PixIconButton\n        @icon=\"{{if (eq this.order \"asc\") \"arrow-up\" \"arrow-down\"}}\"\n        @triggerAction={{this.toggleSort}}\n        @withBackground={{false}}\n        @size=\"small\"\n        @color=\"dark-grey\"\n        aria-label={{@ariaLabel}}\n      />\n    {{/unless}}\n  </span>\n</Table::Header>", {"contents":"<Table::Header @size={{@size}} @align={{@align}} aria-sort={{this.order}} ...attributes>\n  <span class=\"table__column--sort\">\n    {{yield}}\n    {{#unless @isDisabled}}\n      <PixIconButton\n        @icon=\"{{if (eq this.order \"asc\") \"arrow-up\" \"arrow-down\"}}\"\n        @triggerAction={{this.toggleSort}}\n        @withBackground={{false}}\n        @size=\"small\"\n        @color=\"dark-grey\"\n        aria-label={{@ariaLabel}}\n      />\n    {{/unless}}\n  </span>\n</Table::Header>","moduleName":"pix-orga/components/table/header-sort.hbs","parseOptions":{"srcName":"pix-orga/components/table/header-sort.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class HeaderSort extends Component {
  @tracked order = this.args.defaultOrder || 'desc';

  @action
  toggleSort() {
    if (this.order === 'desc') {
      this.order = 'asc';
    } else {
      this.order = 'desc';
    }
    this.args.onSort(this.order);
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Header ...attributes>\n  <Ui::SearchInput\n    class=\"table__input\"\n    @inputName={{@field}}\n    @value={{@value}}\n    @placeholder={{@placeholder}}\n    @ariaLabel={{@ariaLabel}}\n    @onSearch={{this.onSearch}}\n  />\n</Table::Header>", {"contents":"<Table::Header ...attributes>\n  <Ui::SearchInput\n    class=\"table__input\"\n    @inputName={{@field}}\n    @value={{@value}}\n    @placeholder={{@placeholder}}\n    @ariaLabel={{@ariaLabel}}\n    @onSearch={{this.onSearch}}\n  />\n</Table::Header>","moduleName":"pix-orga/components/table/header-filter-input.hbs","parseOptions":{"srcName":"pix-orga/components/table/header-filter-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'pix-orga/config/environment';
import debounce from 'lodash/debounce';

export default class HeaderFilterInput extends Component {
  constructor() {
    super(...arguments);
    this.debouncedTriggerFiltering = debounce(this.args.triggerFiltering, ENV.pagination.debounce);
  }

  @action
  onSearch(event) {
    const { field } = this.args;
    this.debouncedTriggerFiltering(field, event.target.value);
  }
}

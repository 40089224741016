import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"recommendation-indicator\">\n  <svg height=\"10\" width={{this.bubbleWidth}} aria-label={{this.label}}>\n    {{#each this.bubbles as |bubble index|}}\n      <circle cx={{sum (multiply 12 index) 5}} cy=\"5\" r=\"5\" class=\"recommendation-indicator__bubble\"></circle>\n    {{/each}}\n  </svg>\n</div>", {"contents":"<div class=\"recommendation-indicator\">\n  <svg height=\"10\" width={{this.bubbleWidth}} aria-label={{this.label}}>\n    {{#each this.bubbles as |bubble index|}}\n      <circle cx={{sum (multiply 12 index) 5}} cy=\"5\" r=\"5\" class=\"recommendation-indicator__bubble\"></circle>\n    {{/each}}\n  </svg>\n</div>","moduleName":"pix-orga/components/campaign/analysis/recommendation-indicator.hbs","parseOptions":{"srcName":"pix-orga/components/campaign/analysis/recommendation-indicator.hbs"}});
import Component from '@glimmer/component';

const RECOMMENDED = 75;
const HIGHLY_RECOMMENDED = 50;
const STRONGLY_RECOMMENDED = 25;

export default class RecommendationIndicator extends Component {
  get bubblesCount() {
    const value = this.args.value;
    if (value <= STRONGLY_RECOMMENDED) return 4;
    if (value <= HIGHLY_RECOMMENDED) return 3;
    if (value <= RECOMMENDED) return 2;
    return 1;
  }

  get bubbles() {
    return new Array(this.bubblesCount);
  }

  get label() {
    const value = this.args.value;
    if (value <= STRONGLY_RECOMMENDED) return 'Fortement recommandé';
    if (value <= HIGHLY_RECOMMENDED) return 'Très recommandé';
    if (value <= RECOMMENDED) return 'Recommandé';
    return 'Assez recommandé';
  }

  get bubbleWidth() {
    return 12 * this.bubblesCount;
  }

  get xBubbleCoordinate() {
    return 5 * this.bubbles.length;
  }
}

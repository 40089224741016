import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div id=\"icon-trigger\" class=\"dropdown\" ...attributes>\n  <PixIconButton\n    @icon={{@icon}}\n    aria-label=\"{{@ariaLabel}}\"\n    class=\"{{@dropdownButtonClass}}\"\n    @triggerAction={{this.toggle}}\n    @size=\"small\"\n    @color=\"dark-grey\"\n  />\n  <Dropdown::Content @display={{this.display}} @close={{this.close}} class=\"{{@dropdownContentClass}}\">\n    {{yield}}\n  </Dropdown::Content>\n</div>", {"contents":"<div id=\"icon-trigger\" class=\"dropdown\" ...attributes>\n  <PixIconButton\n    @icon={{@icon}}\n    aria-label=\"{{@ariaLabel}}\"\n    class=\"{{@dropdownButtonClass}}\"\n    @triggerAction={{this.toggle}}\n    @size=\"small\"\n    @color=\"dark-grey\"\n  />\n  <Dropdown::Content @display={{this.display}} @close={{this.close}} class=\"{{@dropdownContentClass}}\">\n    {{yield}}\n  </Dropdown::Content>\n</div>","moduleName":"pix-orga/components/dropdown/icon-trigger.hbs","parseOptions":{"srcName":"pix-orga/components/dropdown/icon-trigger.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class IconTrigger extends Component {
  @tracked display = false;

  @action
  toggle() {
    this.display = !this.display;
  }

  @action
  close() {
    this.display = false;
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Table::Header ...attributes>\n  <PixSelect\n    id=\"select-{{@field}}\"\n    class=\"table__input\"\n    aria-label={{@ariaLabel}}\n    @onChange={{this.onChange}}\n    @options={{@options}}\n    @selectedOption={{@selectedOption}}\n    @emptyOptionLabel={{@emptyOptionLabel}}\n  />\n</Table::Header>", {"contents":"<Table::Header ...attributes>\n  <PixSelect\n    id=\"select-{{@field}}\"\n    class=\"table__input\"\n    aria-label={{@ariaLabel}}\n    @onChange={{this.onChange}}\n    @options={{@options}}\n    @selectedOption={{@selectedOption}}\n    @emptyOptionLabel={{@emptyOptionLabel}}\n  />\n</Table::Header>","moduleName":"pix-orga/components/table/header-filter-select.hbs","parseOptions":{"srcName":"pix-orga/components/table/header-filter-select.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class HeaderFilterSelect extends Component {
  @action
  onChange(event) {
    const { triggerFiltering, field } = this.args;
    triggerFiltering(field, event.target.value);
  }
}

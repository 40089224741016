import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"list-students-page__header\">\n  <div class=\"page__title page-title\">{{t \"pages.students-sup.title\"}}</div>\n  {{#if this.currentUser.isAdminInOrganization}}\n    <div class=\"list-students-page__import-students-button hide-on-mobile\">\n      <PixButtonLink\n        @href={{this.urlToDownloadCsvTemplate}}\n        @backgroundColor=\"transparent-light\"\n        target=\"_blank\"\n        rel=\"noopener noreferrer\"\n        download\n      >\n        {{t \"pages.students-sup.actions.download-template\"}}\n      </PixButtonLink>\n      <PixButtonLink @route=\"authenticated.sup-students.import\">\n        {{t \"pages.students-sup.actions.import-file\"}}\n      </PixButtonLink>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"list-students-page__header\">\n  <div class=\"page__title page-title\">{{t \"pages.students-sup.title\"}}</div>\n  {{#if this.currentUser.isAdminInOrganization}}\n    <div class=\"list-students-page__import-students-button hide-on-mobile\">\n      <PixButtonLink\n        @href={{this.urlToDownloadCsvTemplate}}\n        @backgroundColor=\"transparent-light\"\n        target=\"_blank\"\n        rel=\"noopener noreferrer\"\n        download\n      >\n        {{t \"pages.students-sup.actions.download-template\"}}\n      </PixButtonLink>\n      <PixButtonLink @route=\"authenticated.sup-students.import\">\n        {{t \"pages.students-sup.actions.import-file\"}}\n      </PixButtonLink>\n    </div>\n  {{/if}}\n</div>","moduleName":"pix-orga/components/student/sup/header-actions.hbs","parseOptions":{"srcName":"pix-orga/components/student/sup/header-actions.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ENV from 'pix-orga/config/environment';

export default class SupHeaderActions extends Component {
  @service currentUser;
  @service session;

  get urlToDownloadCsvTemplate() {
    return `${ENV.APP.API_HOST}/api/organizations/${this.currentUser.organization.id}/schooling-registrations/csv-template?accessToken=${this.session.data.authenticated.access_token}&lang=${this.currentUser.prescriber.lang}`;
  }
}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"progress-bar\" aria-hidden={{true}}>\n  <div class=\"progress-bar progress-bar--completion\" style={{this.progressBarStyle}}></div>\n</div>", {"contents":"<div class=\"progress-bar\" aria-hidden={{true}}>\n  <div class=\"progress-bar progress-bar--completion\" style={{this.progressBarStyle}}></div>\n</div>","moduleName":"pix-orga/components/ui/progress-bar.hbs","parseOptions":{"srcName":"pix-orga/components/ui/progress-bar.hbs"}});
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/string';

export default class ProgressBar extends Component {
  get progressBarStyle() {
    return htmlSafe(`width: ${this.args.value * 100}%`);
  }
}
